/*jslint browser: true*/
/*global $, define, JSON*/
;(function(root, factory) {

  'use strict';

  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module
    define(['jquery'], factory);
  } else {
    // Browser globals
    return factory($, root);
  }

}(typeof global === 'object' ? global : this, function($, root) {

  'use strict';

  function Tour(opts) {
    this.opts = opts || {};
    this.mode = opts.mode || 'desktop';
    this.bootstrap();
  }

  Tour.prototype.bootstrap = function() {
    this.loadStyles();
    this.loadSteps();
  };

  Tour.prototype.loadIntroJs = function(callback) {
    if (typeof require === 'function') {
      require(['intro.js'], function(IntroJs) {
        callback(IntroJs);
      });
    } else {
      callback(window.introJs);
    }
  };

  Tour.prototype.loadSteps = function(mode) {
    var self = this;
    if (mode) this.mode = mode;

    $.ajax('/tour', {
      dataType: 'json',
      data: {
        m: this.mode
      },
      success: function(data) {
        self.loadIntroJs(function(IntroJs) {
          // For some reason (intro.js bug?) when minified, intro.js
          // exports an object containing the factory function, rather than
          // the function itself.
          self.tour = $.isFunction(IntroJs) ? IntroJs() : IntroJs.introJs();
          self.configure(data);
          if (self.opts.start) setTimeout(function() { self.start(); }, 200);
        });
      }
    });
  };

  Tour.prototype.configure = function(data) {
    data.steps = $.map(data.steps, function(step) {
      step.element = document.querySelector(step.element);
      return step;
    });
    this.tour.setOptions(data);
  };

  Tour.prototype.start = function() {
    this.tour.start();
  };

  Tour.prototype.exit = function() {
    this.tour.exit();
  };

  Tour.prototype.onexit = function(cb) {
    this.tour.onexit(cb);
  };

  Tour.prototype.oncomplete = function(cb) {
    this.tour.oncomplete(cb);
  };

  Tour.prototype.loadStyles = function() {
    var css  = document.createElement('link');
    css.rel  = 'stylesheet';
    css.type = 'text/css';
    css.href = '/assets/css/introjs.css';
    document.getElementsByTagName('head')[0].appendChild(css);
  };

  if (root) root.Tour = Tour;
  return Tour;

}));
