/*global window, document, $, Modernizr*/
//
// soysuper.pager.js
//
// jQuery plugin to replace a "classic", html pager
// by an infinite scroll pagination.
//
;(function() {

  "use strict";

  var Pager = function(el, opts) {
    this.$el         = el;
    this.$container  = $(opts.container);
    //this.$scrollable = $(opts.scrollable) || $(document);
    this.$scrollable = $(document);
    this.param       = opts.param || 'page';
    this.page        = opts.page || 1;
    this.anchors     = {};
    this.hasNext     = true;
    this.hasPrev     = this.page > 1 ? true : false;
    this.callbacks   = {
        beforeSend: $.proxy(opts.beforeSend, this),
        success: $.proxy(opts.success, this),
        error: $.proxy(opts.error, this),
        complete: $.proxy(opts.complete, this)
    };

    var $anchor = this.$container.children().first();
    $anchor.data('page', this.page);
    this.anchors[this.page] = $anchor.position().top;

    if (!this.$container.length)
      throw 'Need a container to append new pages!';

    this.$scrollable.on('scroll', $.proxy(this.scrolled, this));
    this.$el.hide();
  };

  Pager.prototype = {
    constructor: Pager,

    getScrollTop: function() {
      if (this.oldIE()) {
        return Math.abs(this.$container.offset().top + 250);
      }
      else {
        return this.$scrollable.scrollTop();
      }
    },

    oldIE: function() {
      return !Modernizr.mq('only all');
    },

    scrolled: function(ev) {
      var scrollTop     = this.getScrollTop(),
          height        = this.$scrollable.height() - window.innerHeight,
          lastScrollTop = this.lastScrollTop || 0;

      if (scrollTop > lastScrollTop) { this.scrolledDown(scrollTop, height); }
      else                           { this.scrolledUp(scrollTop, height); }

      this.lastScrollTop = scrollTop;
    },

    scrolledDown: function(scrollTop, height) {
      if (scrollTop > height-500 && this.hasNext && !this.fetching) {
        this.fetch('next');
      }
      else if (this.anchors) {
        var page = this.pageChanged(scrollTop);
        if (page && Modernizr.history) {
          window.history.pushState(null, '', this.pageUrl(page));
        }
      }
    },

    scrolledUp: function(scrollTop, height) {
      if (scrollTop < height * 2 && this.hasPrev && !this.fetching) {
        var pager  = this,
            $first = this.$container.children().first();

        this.fetch('prev').done(function() {
          pager.$scrollable.scrollTop($first.position().top);
        });
      }
      else if (this.anchors) {
        var page = this.pageChanged(scrollTop);
        if (page && Modernizr.history) {
          window.history.pushState(null, '', this.pageUrl(page));
        }
      }
    },

    resetAnchors: function() {
      var anchors = this.anchors = this.anchors || {},
          $pages  = this.$container.children().filter(
            function() { return $(this).data('page'); }
          );
      $pages.each(function(_, page) {
        anchors[$(page).data('page')] = $(page).position().top;
      });
    },

    pageChanged: function(scrollPos) {
      var newpage,
          height = this.$scrollable.height() - window.innerHeight,
          pager  = this;

      $.each(this.anchors, function(page, pos) {
        page *= 1;
        if (Math.abs(scrollPos - pos) < 240 && page !== pager.page) {
          newpage = pager.page = page;
        }
      });
      return newpage;
    },

    fetch: function(dir) {
      var pager = this,
          page  = this[dir === 'next' ? 'nextPage' : 'prevPage'](),
          url   = this.pageUrl(page);

      if (!page) return $.Deferred();
      for (var p in this.anchors) {
        if (this.anchors.hasOwnProperty(p) && p * 1 === page) {
          return $.Deferred();
        }
      }

      this.fetching = true;

      return $.ajax(url, {
        dataType: 'html',
        cache: false,

        beforeSend: function(xhr) {
          xhr.setRequestHeader('X-PJAX', true);
          if ($.isFunction(pager.callbacks.beforeSend)) {
            pager.callbacks.beforeSend();
          }
        },
        success: function(data, textStatus, xhr) {
          var $data = $(data.replace(/^\s+/, ''));
          // Before inserting, mark the first element of the page
          $data.first().data('page', page);

          if ($.isFunction(pager.callbacks.success)) {
            pager.callbacks.success($data, textStatus, xhr);
          }
          pager.$container[dir === 'next' ? 'append' : 'prepend']($data);
          pager.resetAnchors();
        },
        error: function(xhr, textStatus, err) {
          if ($.isFunction(pager.callbacks.error)) {
            pager.callbacks.error(xhr, textStatus, err);
          }
          if (xhr.status === 404) {
            pager.hasNext = false;
            pager.$scrollable.off('scroll');
          }
        },
        complete: function(xhr, textStatus) {
          if ($.isFunction(pager.callbacks.complete)) {
            pager.callbacks.complete(xhr, textStatus);
          }
          pager.fetching = false;
        }
      });
    },

    nextPage: function() {
      var pages = this._getPages(),
          page  = (pages[pages.length - 1] || 1) + 1;
      this.hasPrev = page > 1 ? true : false;
      return page;
    },

    prevPage: function() {
      var pages = this._getPages(),
          page  = (pages[0] || 1) - 1;
      this.hasPrev = page > 1 ? true : false;
      return page;
    },

    _getPages: function() {
      return $.map(this.anchors, function(_, k) { return k * 1; }).
        sort(function(a, b) { return a > b; });
    },

    pageUrl: function(page) {
      var base   = window.location.pathname,
          search = window.location.search,
          hash   = window.location.hash,
          re     = this.paramRegExp();

      if (re.test(search)) {
        search = search.replace(re, "$1" + page);
      }
      else {
        var op = search ? '&' : '?';
        search = search + op + this.param + '=' + page;
      }

      return base + search + hash;
    },

    paramRegExp: function() {
      this._paramRegExp = this._paramRegExp || (new RegExp('(' + this.param + '=)\\d+'));
      return this._paramRegExp;
    }

  };

  $.fn.pager = function(opt) {
    if (!this.data('pager')) {
      opt = typeof opt === 'object' ? opt : this.data() || {};
      this.data('pager', new Pager(this, opt || this.data() || {}));
    }
    return this;
  };

  $(function() {
    var $el = $('[data-toggle="pager"]');
    if ($el.length) $el.pager();
  });

})();
