/*jshint multistr: true */
/*global window, document, Visibility*/
;(function($) {

  "use strict";

  // Type extensions
  Number.prototype.toPrice = function() {
    var abs = Math.abs(this);
    return this.toNumber() +
      ',' + (abs - parseInt(abs, 10)).toFixed(2).slice(2) +
      ' €';
  };

  Number.prototype.toNumber = function() {
    return (this < 0 ? '-' : '') +
      (parseInt(Math.abs(this), 10) + '').replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&.');
  };

  // Extend String prototype as well
  String.prototype.toPrice = function() {
    var float = parseFloat(this.replace(',', '.'));
    if ($.isNumeric(float) && float == +float) return this.toString();
    return float.toPrice();
  };

  var getContext = function() {
    var $script = $('script[id=visibility]');
    return $script.length && JSON.parse($script[0].innerHTML);
  };

  var getCouponPids = function() {
    var $script = $('script[id=pids-for-cashback]');
    return $script.length && JSON.parse($script[0].innerHTML);
  };

  var bindClick = function($product, context) {
    if (typeof Visibility === 'undefined') return;
    context = context || {};
    $product.on('click', '.addproduct', function(ev) {
      var agent   = new Visibility.Agent(),
          data    = {
            event: 'cart',
            action: $product.find('[data-visibility-id]').data('visibility-id'),
            rid:    context.nrid
          },
          $target = $(ev.target);
      if (context.product) data.product = context.product;
      agent.save(data);
      $target.addClass('disabled');
      $.ajax($target.attr('href'), {
        type: 'POST',
        headers: { 'Request-Id': context.nrid },
        data: $target.data(),
        success: function() {
          window.location.reload();
        }
      });
      return false;
    }).on('click', function(ev) {
      var $target = $(ev.target);
      if ($target.data('visibility-id')) { ev.preventDefault(); return; } // dk???
      var $visibility = $product.find('[data-visibility-id]');
      var url = $visibility.attr('href');
      // Trick to send the event to visibility (the handler on the vizz.js
      // it sends the event to visibility and then moves to the corresponding url
      $visibility.click();
      if ( context.rid ) { url = url +'?rid='+ context.rid; }
      window.location = url;
      return false;
    });
  };

  var displayBanner = function(banner, context) {
    var tmpl =
      '<section class="ad"> \
        <a href="" title="" data-visibility-rid="" data-visibility-id="" data-visibility-event="click" data-prevent-default="true"> \
          <img src="" alt=""> \
        </a> \
        <p class="hidden-t"> \
          Categoría patrocinada \
        </p> \
      </section>';
    var $banner = $(tmpl);
    $banner.find('a').
      attr('href', banner.attributes.href).
      attr('title', banner.attributes.title).
      attr('data-visibility-id', banner.id).
      attr('data-visibility-rid', context.rid);
    $banner.find('img').
      attr('src', banner.attributes.src).
      attr('alt', banner.attributes.title);

    $('section.display h1').after($banner);
  };

  var displayBrandSite = function(item) {
    $('#container').prepend($(item.html));
  };

  var displayProduct = function(product, context) {
    var tmpl =
    '<section class="block" data-pid=""> \
       <h3> \
         <img src="" style="display: inline-block"> Te recomienda \
       </h3> \
       <section class="inner"> \
         <section class="oneproduct"> \
           <p class="clearfix"> \
             <span class="details coupon-container"> \
               <a href="" class="name" data-visibility-id="" data-visibility-event="click" data-visibility-product="" data-prevent-default="true"></a> \
               <span class="price"></span> \
               <span class="unitprice"></span> \
             </span> \
             <span class="img"> \
               <img src="" alt=""> \
             </span> \
           </p> \
           <div class="actions"> \
             <a href="/cart/items" class="btn btn-primary addproduct" data-product_id="">Añadir</a> \
           </div> \
         </section> \
       </section> \
     </section>';

    var $product = $(tmpl);
    $product.attr('data-pid', product.attributes.product_id);
    $product.find('h3 img').attr('src', product.brand && product.brand.src);
    $product.find('.details a').
      attr('href', '/p/' + product.attributes.slug).
      attr('data-visibility-id', product.id).
      attr('data-visibility-product', context.product).
      html(product.attributes.brand + '<br>' + product.attributes.name);
    $product.find('.price').text(product.attributes.price.toPrice());
    $product.find('.unitprice').text(
      product.attributes.unit_price.price.toPrice() + ' / 1 ' + product.attributes.unit_price.measure
    );
    $product.find('p .img img').
      attr('src', product.attributes.image.desktop).
      attr('alt', product.attributes.name);
    $product.find('.actions a').
      attr('data-product_id', product.attributes.product_id).
      attr('data-coupon_id', product.attributes.coupon_id);

    bindClick($product, context);

    insertInRightColumn($product);

    if (product.attributes.coupon_id) {
      displayCoupon(product.attributes.product_id, {
        coupon_id: product.attributes.coupon_id,
        deal: product.attributes.deal
      });
    }
  };

  // Displays a product in a "most popular products" section (aka home
  // and supermarket pages.
  var displayPopProduct = function(product, context) {
    var tmpl =
      '<li class="sponsored" data-pid=""> \
         <p class="clearfix"> \
           <a class="name" href="" title="" data-visibility-id="" data-visibility-product="" data-visibility-event="click" data-prevent-default="true"> \
             <span class="brand"></span> \
             <span class="productname"></span> \
           </a> \
           <span class="img"> \
             <img alt="" src=""> \
           </span> \
           <span class="details coupon-container"> \
             <span class="price"></span> \
             <span class="unitprice"></span> \
           </span> \
         </p> \
         <div class="actions adding"> \
           <div class="additem"> \
             <a class="btn btn-primary addproduct btn-block" data-product_id="" data-qty="1" href="/cart/items" rel="nofollow">Añadir</a> \
           </div> \
         </div> \
       </li>';

    var $product = $(tmpl);
    $product.find('p.clearfix a').
      attr('href', '/p/' + product.attributes.slug).
      attr('data-visibility-id', product.id).
      find('.brand').text(product.attributes.brand);
    $product.find('.productname').text(product.attributes.name);

    $product.find('.price').text(product.attributes.price.toPrice() + ' / ' + product.attributes.variant);
    $product.find('.unitprice').text(
      product.attributes.unit_price.price.toPrice() + ' / 1 ' + product.attributes.unit_price.measure
    );

    $product.find('.img img').
      attr('src', product.attributes.image.desktop).
      attr('alt', product.attributes.name);

    $product.attr('data-pid', product.attributes.product_id);
    $product.find('.actions a').
      attr('data-product_id', product.attributes.product_id).
      attr('data-coupon_id', product.attributes.coupon_id);

    bindClick($product, context);

    // Insert template in first position (soysuper/supers#1442)
    $('.productlist').prepend($product);

    if (product.attributes.coupon_id) {
      displayCoupon(product.attributes.product_id, {
        coupon_id: product.attributes.coupon_id,
        deal: product.attributes.deal
      });
    }
  };

  var displayProductInSearch = function(product, context) {
    var tmpl =
      '<li class="ad" data-pid=""> \
         <div class="banner"> \
           <img src="" alt=""> \
           <span>Publicidad</span> \
         </div> \
         <span class="coupon-container"></span> \
         <span class="details"> \
           <a href="" data-visibility-id="" data-visibility-rid="" data-visibility-event="click" data-prevent-default="true"> \
              <span class="name"><br></span> \
              <span class="pricing"> \
                <span class="price"></span> \
                <span class="unitprice"></span> \
              </span> \
           </a> \
         </span> \
         <span class="actions clearfix"> \
           <a href="/cart/items" class="btn btn-primary btn-block addproduct" data-product_id="">Añadir</a> \
         </span> \
       </li>';
    var $product = $(tmpl);
    $product.find('.details a').
      attr('href', '/p/' + product.attributes.slug).
      attr('data-visibility-id', product.id).
      attr('data-visibility-rid', context.rid);

    $product.find('.price').
      text(product.attributes.price.toPrice() + ' / ' + product.attributes.variant);

    $product.find('img').
      attr('src', product.banner.src).
      attr('alt', product.banner.title);

    $product.attr('data-pid', product.attributes.product_id);
    $product.find('.btn.addproduct').
      attr('data-product_id', product.attributes.product_id).
      attr('data-coupon_id', product.attributes.coupon_id);

    bindClick($product, context);

    insertInSearch($product);

    if (product.attributes.coupon_id) {
      displayCoupon(product.attributes.product_id, {
        coupon_id: product.attributes.coupon_id,
        deal: product.attributes.deal
      });
    }
  };

  var displayBannerInSearch = function(action, context) {
    var tmpl =
      '<li class="ad"> \
         <div class="banner"> \
          <a href="" data-visibility-id="" data-visibility-rid="" data-visibility-event="click" data-prevent-default="1"> \
            <img src="" title=""> \
          <span>Publicidad</span> \
          </a> \
        </div> \
      </li>';

    var $banner = $(tmpl);
    $banner.find('a').
      attr('href', action.attributes.href).
      attr('data-visibility-id', action.id).
      attr('data-visibility-rid', context.rid);

    $banner.find('a img').
      attr('src', action.attributes.src).
      attr('title', action.attributes.title);

    insertInSearch($banner);
  };

  var displayBannerInSuggestions = function(action, context) {
    var tmpl =
      '<section class="block"> \
         <!-- <h3><img src=""></h3> --> \
         <section class="inner"> \
           <section class="oneproduct"> \
             <a href="" class="name" data-visibility-id="" data-visibility-rid="" data-visibility-event="click" data-prevent-default="1"> \
               <img src=""> \
             </a> \
           </section> \
         </section> \
       </section>';
    var $banner = $(tmpl);
    $banner.find('h3 img').
      attr('src', action.brand.src);

    $banner.find('a').
      attr('href', action.attributes.href).
      attr('data-visibility-id', action.id).
      attr('data-visibility-rid', context.rid);

    $banner.find('a img').
      attr('src', action.attributes.src);

    insertInRightColumn($banner);
  };

  var insertInSearch = function($el) {
    // Insert template at 8th index in products listing, or last one
    // if results are less than 8
    if ($('.productlist li').length > 8) {
      $('.productlist li:nth-child(8)').after($el);
    } else {
      $('.productlist li:last').after($el);
    }
  };

  var displayCoupon = function(pid, coupon) {
    var $product = $('[data-pid=' + pid + ']'),
        $couponContainer = $product.find('.coupon-container'),
        $addLink = $product.find('a.newproduct'),
        deal = coupon.deal;

    var tmpl =
        '<span class="coupon"> \
           <b></b><span></span><em></em> \
         </span>';
    if ($product.length) {
      var $tmpl = $(tmpl);
      var symbolPos = deal.abbr.indexOf(deal.symbol) + 1;
      $tmpl.find('b').text(
        (deal.abbr.slice(0, symbolPos) || '').replace(/^\s+|\s+$/g, '')
      );
      $tmpl.find('span').text(
        (deal.abbr.slice(symbolPos) || '').replace(/^\s+|\s+$/g, '').toUpperCase()
      );
      $tmpl.find('em').text(deal.symbol);
      $couponContainer.prepend($tmpl);
      if ($addLink.length) $addLink.data({coupon_id: coupon.coupon_id});
    }
  };

  var insertInRightColumn = function($el) {
    var $rightcolumn = $('#rightcolumn');
    if ($rightcolumn.length) {
      $rightcolumn.find('#promo').html($el);
      // Ensure rightcolumn promo slot is visible in all modes
      $rightcolumn.find('#suggestions').addClass('hidden-t');
      $rightcolumn.removeClass('hidden-t');
    }
  };

  $(function() {
    var opts = getContext(), couponPids = getCouponPids();
    if (!(opts || couponPids)) return;
    if (opts) {
      opts = $.isArray(opts) ? opts : [opts];

      $.each(opts, function(_, o) {
        if (typeof Visibility === 'undefined') return;
        var manager = new Visibility.Manager(o);
        manager.fetch(function(item) {
          if (o.ctx === 'category' || (o.ctx === 'brand' && !item.html)) {
            displayBanner(item, o);
          } else if (o.ctx === 'brand' && item.html) {
            displayBrandSite(item);
          } else if (o.ctx === 'search' || o.ctx === 'csearch') {
            if (item.attributes.product_id) {
              displayProductInSearch(item, o);
            } else {
              displayBannerInSearch(item, o);
            }
          } else if (o.ctx === 'homepop' || o.ctx === 'superpop') {
            displayPopProduct(item, o);
          } else if (item.attributes.product_id) {
            displayProduct(item, o);
          } else {
            displayBannerInSuggestions(item, o);
          }
        });
      });
    }

    if (couponPids) {
      (new Visibility.Manager()).fetchCoupons({p: couponPids.pids}).done(function(coupons) {
        $.each(coupons, function(_, coupon) {
          $.each(coupon.product_id || [], function(_, id) {
            displayCoupon(id, coupon);
          });
        });
      });
    }
  });

})(jQuery);
