if (!Array.prototype.indexOf) {
  Array.prototype.indexOf = function(elt) {
    var len = this.length >>> 0;
    var from = Number(arguments[1]) || 0;
    from = (from < 0) ? Math.ceil(from) : Math.floor(from);
    if (from < 0) from += len;
    for (; from < len; from++) {
      if (from in this && this[from] === elt) {
        return from;
      }
    }
    return -1;
  };
}

$(function() {
  // Checkboxes
  if ($('i[class^=i-ss-checkbox]').length > 0) {
    $('i[class^=i-ss-checkbox]').on('click', function(e){
      var $this     = $(this),
          $checkbox = $this.siblings('input[type=checkbox]'),
          $li       = $this.parent('li');
      $this.prop('class', $this.hasClass('i-ss-checkbox-checked') ? 'i-ss-checkbox' : 'i-ss-checkbox-checked');
      if ($checkbox.length) {
        $checkbox.prop('checked', function(idx, value) { return !value; });
      }
      $li.toggleClass('deselected').toggleClass('selected');
      $li.find('.btn').toggleClass('disabled');
    });
  }

  // Radios
  if ($('.radio').length > 0) {
    $('.radio:not(.disabled)').on('click', function(e){
      e.preventDefault();
      var $this = $(this);
      if ($this.hasClass('radio--checked')) return false;
      $this.closest('ul').find('.radio').
        removeClass('radio--checked').
        children('i.i-ss-radio-checked').prop('class', 'i-ss-radio');
      $this.addClass('radio--checked').
        children('i.i-ss-radio').prop('class', 'i-ss-radio-checked');
      $this.trigger('checked');
    });
  }

  // Placeholders
  $('input, textarea').placeholder();

  // Filters
  if($('.filters').length > 0){
    $('.filters h3').on('click', function(){
      $(this).next('.selectoralike').toggle();
      $(this).toggleClass('closed');
    });
  }

  /*
   * Desktop specific behaviours
   */
  // Benefits column height
  if($('#benefitscolumn').length > 0){
    $('#benefitscolumn .inner').css('min-height', $('#formdetailscolumn .inner').height());
  }

  // Default left column: show all categories of level 2
  $(document).on('click', '.showall a', function() {
    var $li     = $(this).parent(),
        $hidden = $li.siblings();
    $hidden.show();
    $li.hide();
    return false;
  });

  /*
   * Mobile specific behaviours
   */
  // Hovers
  if ($('.basiclist').length > 0) {
    $('.basiclist').on('click', 'p', function(e) {
      $(this).addClass('active');
      if ($(e.target).attr('href')) return;
      var $a = $(this).find('a:first');
      if ($a.length) window.location.assign($a.attr('href'));
    });
  }

  // Showing Side Area
  if ($('#sidearea').length > 0) {
    $('#showsidearea').on('click', function(ev) {
      var maskHeight = $(document).height();
      $('#viewport').addClass('sideareashowing');
      $('#page, #header').animate({ left: 240 }, 20);
      $('#sidearea').animate({ left: 0 }, 20);
      $('<div id="pagemask"></div>').height(maskHeight).prependTo('#page');
    });

    $(document).on('click', '#pagemask', function(){
      $(this).remove();
      $('#page, #header').animate({
        left: 0
      }, 200, function(){
        $('#viewport').removeClass('sideareashowing');
      });
      $('#sidearea').animate({ left: -240 }, 20);
    });

    $('#sidearea').height($(window).height());
  }

  // Show trigger
  if ($('#filterstrigger').length > 0){
    $('#leftcolumn').hide();
    $("#filterstrigger").on('click', function() {
      $('#leftcolumn').show();
    });
  }

  // Show search
  if ($('#search').length){
    $("#search").on('click', function(e) {
      e.preventDefault();
      if ($('#searchbox').is(':visible')) {
        $('#page').removeClass('withsearchbox');
        $('#searchbox').hide();
      } else {
        $('#page').addClass('withsearchbox');
        $('#searchbox').show(); // .find('input:text').focus();
      }
    });
  }

  if ($('.dropdown').length > 0) {
    $('.btn-dropdown').on('click', function(e){
      var $this   = $(this),
          $menu   = $this.next('.dropdown-menu'),
          $target = $(e.target);

      $this.addClass('active');
      $menu.toggle();
      e.preventDefault();
      if ($target.hasClass('btn-dropdown')) {
        $this.removeClass('active');
        e.stopPropagation();
      }

      $('html, .dropdown-menu').one('click', function(e) {
        if ($menu.is(':visible')) {
          $menu.hide();
          $this.removeClass('active');
        }
      });
    });
  }

  // Feedback button expander effect
  $(document).on('mouseenter', '#tellus', function(e) {
    $(this).find('.extra').show();
  });
  $(document).on('mouseleave', '#tellus', function(e) {
    $(this).find('.extra').hide('fast');
  });

  var ua = window.navigator.userAgent;
  if (ua.match(/Android 4.[12]/) && ua.match(/AppleWebKit\/534.30/)) {
    // Hack to prevent Android Browser on Android 4.1.x borking click
    // handlers attached to elements within a scrolling container
    $('html, body, #viewport, #page, #container').css({
      height: 'auto',
      overflow: 'visible'
    });
    $('#sidearea').offset({ top: 0 });
  }

  // Fix iPad/iPhone Safari header's top offset handling on resize
  if (/(iPhone|iPad)/.test(window.navigator.userAgent)) {
    $(document).on('focus blur', 'input[type=text], textarea', function() {
      $(document).scrollTop(0);
    });
  }

});
