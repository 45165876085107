;(function($) {

  $.fn.fixBreadcrumb = function() {
    if (!this.is(':visible')) return;

    var $this     = this
      , $items    = this.find('.breadcrumbs__item')
      , maxWidth  = this.width() - 10;

    function getItemWidths(outer) {
      return $.map($items, function(i) {
        return $(i)[outer ? 'outerWidth' : 'width']();
      });
    }

    function getWidth() {
      var i = 0;
      $.each(getItemWidths(true), function(_, w) { i += w; });
      return i;
    }

    var initialItemWidths = getItemWidths()
      , diff              = getWidth() - maxWidth
      , min_width         = 25;

    if (diff > 0) {
      $.each($items, function(i) {
        var $item      = $(this);
        var item_width = initialItemWidths[i];

        $item.css({ overflow: 'hidden', textOverflow: 'ellipsis' });

        if ( diff > 0 && item_width > min_width ) {
          var max_to_take = item_width - min_width;
          if ( max_to_take > 0 ) {
            var to_width    = 0;

            if ( max_to_take > diff ) {
              to_width = item_width - diff;
              diff     = 0;
            }
            else {
              to_width = item_width - max_to_take;
              diff     = diff - max_to_take;
            }

            if ( to_width ) {
              $item.css({ width: to_width });
            }
          }

        }
      });
    }

    return $this;
  };

  $(function() {
    var $el = $('[data-type=breadcrumb]');
    if ($el.length) {
      $el.each(function() { $(this).fixBreadcrumb(); });
    }
  });
})(window.jQuery);
