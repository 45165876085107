/*jslint browser: true*/
/*global $, define, JSON, Modernizr*/
;(function(root, factory) {

  'use strict';

  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module
    define(['jquery'], factory);
  } else {
    // Browser globals
    return factory($, root);
  }

}(typeof global === 'object' ? global : this, function($, root) {

  'use strict';

  function Gallery(el, opts) {
    this.config = opts || {};
    this.$el    = $(el);
    this.images = this.config.images || this.parseImages();
    this.mode = this.config.mode || 'desktop';

    this.bindToEvents();
  }

  Gallery.prototype = {

    constructor: Gallery,

    // Read images data from the markup
    parseImages: function() {
      var $data = this.$el.find('#gallery-data');
      if (!$data.length) return;
      return JSON.parse($data.html());
    },

    bindToEvents: function() {
      this.$el.
        on('click', '.product__gallery ul > li > a', $.proxy(this.changeMain, this)).
        on('click', '.img.zoomable', $.proxy(this.zoom, this)).
        on('gallery.changemain', $.proxy(function() {
          this.updateActive(this.$el.find('.product__gallery ul'));
        }, this));
    },

    changeMain: function(e, opts) {
      if (e) e.preventDefault();
      opts = opts || {};
      var idx        = opts.idx != void 0 ? opts.idx : $(e && e.currentTarget).find('img').data('idx'),
          src        = this.getImageUri(idx, 'medium'),
          $link      = this.$el.find('.img img'),
          $wrapper   = $link.parent(),
          $container = $('.js-product-image'),
          onload;

      if (idx == void 0 || !src || src === $link.prop('src')) return;
      this.active = idx;
      if (this.getImageUri(idx, 'large')) {
        onload = $.proxy(function() {
          $('<a href="#product-gallery-modal" class="img zoomable"><span class="loupe">+</span></a>').
            prepend($link).
            prependTo($container);
          this.$el.trigger('gallery.changemain');
        }, this);
      }
      else {
        onload = $.proxy(function() {
          $('<span class="img"></span>').html($link).prependTo($container);
          this.updateActive(this.$el);
        }, this);
      }
      this.swapImage($link, src, { onload: onload, onunload: function(){$wrapper.remove();} });
    },

    zoom: function() {
      var $modal = this.modal(),
          src    = this.getImageUri(this.active, 'large'),
          self   = this;

      if (!src) return;
      $modal.superpop('show');
      this.loadZoomedImage(src, function() {
        $modal.prepend($(this));
        self.enableZoom();
        if (!Modernizr.touch) $(this).trigger('mouseenter');
      });
    },

    loadZoomedImage: function(src, fn) {
      var self = this;

      if (!this.$zoom) {
        this.$zoom = this.$modal.find('img.active-image');
        if (!this.$zoom.length) this.$zoom = $('<img class="active-image">');
      }
      if (fn) {
        if (navigator.userAgent.match(/AppleWebKit/)) {
          this._loadInterval = setInterval(function() {
            if (self.$zoom[0].height) {
              $.proxy(fn, self.$zoom[0])();
              clearInterval(self._loadInterval);
            }
          }, 2);
        } else {
          this.$zoom.one('load', fn);
        }
      }
      this.$zoom.prop('src', src || this.getImageUri(this.active, 'large'));
      return this.$zoom;
    },

    // Build and keep a reference to a modal instance
    modal: function() {
      if (!this.$modal) {
        this.$modal = this.$el.find('.modal').superpop({
          superpop: 'modal',
          show: false
        });
        this.bindToModalEvents();
        this.updateActive(this.$modal);
      }
      return this.$modal;
    },

    bindToModalEvents: function() {
      this.$modal.
        on('click', 'ul > li > a', $.proxy(this.changeZoomed, this)).
        on('hide', $.proxy(this.disableZoom, this)).
        on('click', '.active-image', $.proxy(function() { this.$modal.superpop('hide'); }, this));
      this.$el.
        on('gallery.changemain', $.proxy(function() {
          this.disableZoom();
          this.loadZoomedImage();
          this.updateActive(this.$modal);
        }, this));
    },

    changeZoomed: function(e) {
      e.preventDefault();
      var idx   = $(e.currentTarget).find('img').data('idx'),
          src   = this.getImageUri(idx, 'large'),
          $zoom = this.$zoom;

      if (idx == void 0 || !src || src === $zoom.prop('src')) return;
      this.swapImage($zoom, src, {
        onload: $.proxy(function() {
          this.enableZoom();
          if (!Modernizr.touch) {
            setTimeout($.proxy(function() {
              this.$modal.trigger('mouseenter');
            }, this), 200);
          }
        }, this),
        onunload: $.proxy(this.disableZoom, this)
      });
      this.changeMain(null, { idx: idx });
    },

    // Given a position and a size, look up this.images
    // and retrieve the corresponding uri
    getImageUri: function(idx, size) {
      var images = this.images[this.mode][size];
      if (!$.isArray(images)) return;
      return images[idx || 0];
    },

    swapImage: function($img, src, callbacks) {
      callbacks = callbacks || {};

      var container = $img.closest('div');
      container.height(container.height()); // block height to prevent flickering

      if (callbacks.onunload) callbacks.onunload();
      $img.one('load', function() {
        $img.fadeTo(100,1);
        container.height(container.height()); // block height
        if (callbacks.onload) callbacks.onload();
      });
      $img.fadeTo(50,0);
      $img.prop('src', src);
    },

    updateActive: function($context) {
      if (this.active === void 0) return;
      $context.find('li.active').removeClass('active');
      $context.find('[data-idx=' + this.active + ']')
        .parents('li').addClass('active');
    },

    enableZoom: function() {
      var $modal = this.modal();
      setTimeout($.proxy(function() { this.setInitialPosition(); }, this), 0);
      if (Modernizr.touch) {
        $modal.on('touchstart', $.proxy(function(e) {
          var diffX = e.originalEvent.touches[0].pageX,
              diffY = e.originalEvent.touches[0].pageY,
              img   = this.$zoom[0],
              top   = parseInt(img.style.top, 10),
              left  = parseInt(img.style.left, 10);

          $modal.on('touchmove', $.proxy(function(e) {
            e.preventDefault();
            var pageX = e.originalEvent.touches[0].pageX,
                pageY = e.originalEvent.touches[0].pageY;

            top  = top + pageY - diffY,
            left = left + pageX - diffX;

            img.style.top  = top + 'px';
            img.style.left = left + 'px';

            diffX = pageX;
            diffY = pageY;
          }, this));
        }, this));
      } else {
        $modal.
          on('mouseenter', $.proxy(function() {
            $modal.on('mousemove', $.proxy(this.move, this));
          }, this)).
          on('mouseleave hide', $.proxy(function() {
            $modal.off('mousemove');
          }, this));
      }
    },

    setInitialPosition: function() {
      var img    = this.$zoom[0],
          coords = this.getZoomCoords(img);

      img.style.left = ((coords.outerWidth - img.width) / 2) + 'px';
      img.style.top  = ((coords.outerHeight - img.height) / 2) + 'px';
    },

    move: function(e) {
      e.preventDefault();
      e = e.pageX ? e : e.originalEvent.touches[0];
      var img    = this.$zoom[0],
          coords = this.getZoomCoords(img),
          left   = e.pageX - coords.offset.left,
          top    = e.pageY - coords.offset.top;

      top  = Math.max(Math.min(top, coords.outerHeight), 0);
      left = Math.max(Math.min(left, coords.outerWidth), 0);

      if (coords.setTop)  img.style.top  = (top * -coords.yRatio) + 'px';
      if (coords.setLeft) img.style.left = (left * -coords.xRatio) + 'px';
    },

    getZoomCoords: function(img) {
      if (this.zoomCoords) return this.zoomCoords;

      var outerWidth  = this.$modal.outerWidth(),
          outerHeight = this.$modal.outerHeight();

      return this.zoomCoords = {
        offset: this.$modal.offset(),
        outerWidth: outerWidth,
        outerHeight: outerHeight,
        xRatio: (img.width - outerWidth) / outerWidth,
        yRatio: (img.height - outerHeight) / outerHeight,
        setTop: img.height > outerHeight,
        setLeft: img.width > outerWidth
      };
    },

    disableZoom: function() {
      this.unbindMoveEvent();
      this.zoomCoords = null;
    },

    unbindMoveEvent: function() {
      this.$modal.off('mousemove mouseleave hide touchstart touchmove');
    }

  };

  var old = $.fn.gallery;

  $.fn.gallery = function(opts) {
    return this.each(function() {
      var $this = $(this);
      if ($this.data('gallery')) return;
      $this.data('gallery', new Gallery(this, opts));
    });
  };

  // No conflict
  $.fn.gallery.noConflict = function() {
    $.fn.gallery = old;
    return this;
  };

}));
