;(function(window, $, Sync) {

  'use strict';

  // SuperSignin with Sync powers constructor
  function SuperSignin(opts) {
    if (!(this instanceof SuperSignin)) return new SuperSignin(opts);
    opts = opts || {};
    if (!opts.url) throw new Error(
      'A signin endpoint url must be provided to SuperSignin constructor'
    );
    this.url    = opts.url;
    this.method = opts.method || 'post';
    this.data   = opts.data || '';
    this.$ajax  = $.ajax;
    return this;
  }

  SuperSignin.prototype = {
    constructor: SuperSignin,

    // Perform signin. An options object may be passed with
    // callbacks to valid and invalid events, plus those accepted
    // by a sync channel. Valid (and invalid) events are triggered
    // before establishing a connection to the supermarket, right after
    // user input has been validated on the server.
    submit: function(opts) {
      var signin = this;

      this.$ajax(this.url, {
        type: this.method,
        data: this.data,
        success: function(resp) {
          if ($.isFunction(opts.valid)) opts.valid(resp);
          $.proxy(signin.watch, signin)(resp, opts);
        },
        error: function(resp) {
          if ($.isFunction(opts.invalid)) opts.invalid(resp);
        }
      });
    },

    watch: function(resp, opts) {
      var channelId =
        resp.sync_channel &&
        resp.sync_channel.id;

      if (!channelId) return;

      opts = opts || {};
      this.channel = Sync.open({
        urlRoot: '/sync-channel/',
        id: channelId
      });
      this.channel.message($.proxy(this.pushMessage, this));
      this.attachChannelCallbacks(opts);
      this.channel.connect();
    },

    attachChannelCallbacks: function(callbacks) {
      if ($.isEmptyObject(callbacks)) return;
      if ($.isFunction(callbacks.success)) {
        this.channel.done(callbacks.success);
      }
      if ($.isFunction(callbacks.message)) {
        this.channel.message(callbacks.message);
      }
      if ($.isFunction(callbacks.error)) {
        this.channel.fail(callbacks.error);
      }
    },

    pushMessage: function(msg) {
      (this._messages = this._messages || []).push(msg);
    },

    getLastMessage: function() {
      return this._messages[this._messages.length - 1];
    },

    getErrorMessage: function() {
      var errors = $.grep(this._messages || [], function(msg) {
        return msg['class'] === 'error';
      });
      if (!$.isEmptyObject(errors)) {
        return errors[errors.length - 1];
      }
    }
  };

  // Utility functions to handle the UI on connecting to a super,
  // to be mixed-in by a form object.
  var FormUtils = {
    renderValidationErrors: function(resp) {
      var errors = JSON.parse(resp.responseText);

      for (var field in errors.fields) {
        var $input = this.find('[name=' + field + ']');
        this.renderValidationError($input, errors.fields[field]);
      }
    },

    renderValidationError: function($input, msg) {
      $input.parent('p').addClass('error');
      $input.parent('p').append($('<em class="formtip error">').text(msg));
    },

    renderSigninError: function(msg) {
      if (!msg || $.isEmptyObject(msg)) return;
      var $section = $(this.data('error-section')),
          $msgspan = $section.find('span.supermessage');

      $('section#oauth-error').hide();
      $msgspan.text(msg.txt);
      $section.fadeIn('fast');
    },

    reset: function() {
      this.find('input.error').removeClass('error');
      this.find('em.formtip.error').remove();
    },

    toggle: function() {
      var $button = this.find('button[type=submit]'),
          $span   = $button.find('span.btn-text'),
          text    = $span.text();

      $span.html($span.data('alt'));
      $span.data('alt', text);
      $button.prop('disabled', function(idx, value) { return !value; });
    }
  };

  $(window.document).ready(function() {
    // Close errors section
    $('.close-message').click(function(ev) {
      var $a = $(ev.target),
          $target = $($a.attr('href'));
      $target.fadeOut('fast');
      ev.preventDefault();
    });

    // Subscribe to signin form(s) submit event
    $('form[data-type=signin]').submit(function() {
      var $this = $(this);

      if (!$this.data('handler')) {
        $.extend($this, FormUtils);
        var handler = new SuperSignin({
          url: $this.prop('action'),
          method: $this.prop('method'),
          data: $this.serialize()
        });
        $this.reset();
        handler.submit({
          invalid: function(resp) {
            $this.renderValidationErrors(resp);
          },
          valid: function(resp) {
            if (resp.redirect_to) handler.location = resp.redirect_to;
            $this.data('handler', handler);
            $this.toggle();
          },
          success: function() {
            var msg = handler.getLastMessage();

            if (handler.location) {
              window.location = handler.location;
            } else if (msg && msg.location) {
              window.location = msg.location;
            }
          },
          error: function() {
            $this.toggle();
            $this.renderSigninError(handler.getErrorMessage());
            $this.data('handler', null);
          }
        });
      }

      return false;
    });
  });

  return SuperSignin;

})(window, jQuery, Sync);
