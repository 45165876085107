/*jslint browser: true*/
/*global $, define*/
;(function(root, factory) {

  'use strict';

  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module
    define(['jquery'], factory);
  } else {
    // Browser globals
    return factory($, root);
  }

}(typeof global === 'object' ? global : this, function($, root) {

  'use strict';

  // Polls a channel status, retrieving available
  // messages periodically until either 'success'
  // or 'error' status is received.
  // This module exports a singleton object that
  // represents a channel. Callbacks may be attached
  // to success (done) and error (fail) events, as
  // well as to message recipt events.
  // Usage:
  //   var channel = Sync.open({ id: channelID });
  //   channel.done(callback).fail(callback).message(callback);
  //   channel.connect();
  //
  function Sync() {
    if (!(this instanceof Sync)) return new Sync();
  }

  Sync.prototype = {

    constructor: Sync,

    // Returns an extended promise representing a channel.
    // It exposes, in addition to the well known promise methods,
    // (i.e.: done, fail, status...) a +message+ hook
    // to register a callback that will be invoked on
    // every message receipt.
    open: function(options) {
      options = options || {};
      this.id = options.id;
      if (!this.id) throw new Error(
        "Cannot open a Sync channel without an id"
      );
      this.urlRoot  = options.urlRoot || '/me/sync-channel';
      this.url      = this.urlRoot.replace(/\/$/, '') + '/' + this.id + '/data';
      // Fetch only messages with id greater than the 'after' option.
      this.after    = options.after || 0;

      // Reset polling if currently active.
      if (this.deferred && this.deferred.state() === 'pending')
        this.reset();

      this.deferred = this._getDeferred();
      return this.deferred;
    },

    // Reset current channel polling by clearing scheduled requests
    // and imploding the deferred object and callbacks queue.
    reset: function() {
      if (this._scheduled) clearTimeout(this._scheduled);
      delete this._scheduled;
      this.deferred = null;
      this._messageCallbacks = null;
    },

    // Start polling channel's messages and status!
    connect: function() {
      this.schedule(this.getMessages, this);
    },

    // Actual AJAX request to poll messages and
    // channel status.
    getMessages: function() {
      return $.ajax({
        url: this.url,
        dataType: 'json',
        data: { after: this.after },
        success: $.proxy(this.process, this),
        error: $.proxy(this.fail, this),
        cache: false
      });
    },

    // Handle every successful backend response to channel data
    // requests. Delegate every incoming message handling to
    // +processMessage+ and re-schedule if one of the following
    // conditions are met:
    // a) channel status is unknown (i.e. channel is not accesible yet).
    // b) status is not 'success' or 'error' (i.e. task did not finish).
    process: function(response) {
      var sync   = this,
          status = response.status;

      if (status || (response.messages || []).length) {
        $.each(response.messages, function(_, message) {
          sync.processMessage(message);
        });
        if (status === 'success') {
          this.deferred.resolve();
        } else if (status === 'error') {
          this.deferred.reject();
        } else {
          this.schedule(this.getMessages, this, 1000);
        }
      } else {
        this.schedule(this.getMessages, this, 2000);
      }
    },

    // Process an individual message, invoking callbacks
    // registered via +message+ interface. Track last processed
    // message id for subsequent requests.
    processMessage: function(message) {
      $.each(this._messageCallbacks || [], function(_, callback) {
        if ($.isFunction(callback)) return callback(message);
      });
      this.after = message.id;
    },

    // Catch connection/communication related failures
    // (i.e.: not reported by the channel but thrown by the ajax request)
    fail: function(xhr) {
      this.deferred.reject({
        txt: 'Parece que hay un problema con la conexión a internet...',
        status: 0
      });
    },

    // Wraps creating a timeout. A context for the function
    // may be passed, as well as the number of miliseconds the
    // function call should be delayed by.
    schedule: function(func, context, miliseconds) {
      func = context ? $.proxy(func, context) : func;
      if (this._scheduled) clearTimeout(this._scheduled);
      return this._scheduled = setTimeout(func, miliseconds || 200);
    },

    // Return a jQuery deferred extended with a +message+ method
    // to allow registering a callback chain to be run on every
    // message receipt. Provide also a proxy to Sync's +connect+
    // to start polling the channel.
    _getDeferred: function() {
      var deferred = $.Deferred(),
              sync = this;

      return $.extend(deferred, {
        message: function(func) {
          sync._messageCallbacks = sync._messageCallbacks || [];
          sync._messageCallbacks.push(func);
          return this;
        },
        connect: function() {
          sync.connect();
        }
      });
    }

  };

  var instance = new Sync();
  if (root) root.Sync = instance;
  return instance;

}));
