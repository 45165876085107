/*jslint browser: true*/
/*global $, define*/
;(function(root, factory) {

  'use strict';

  if (typeof define === 'function' && define.amd) {
    // AMD. Register as an anonymous module
    define(['jquery'], factory);
  } else {
    // Browser globals
    return factory($, root);
  }

}(typeof global === 'object' ? global : this, function($, root) {

  'use strict';

  var Superpop = {

    // Builder function to bootstrap either a modal or a popover
    // object.
    //
    build: function(element, options) {
      if (!options && (options.superpop || options.type))
        throw new Error('A type must be provided: modal or popover');

      return new this[options.superpop || options.type](element, options);
    },

    // Modal constructor
    //
    modal: function(element, options) {
      this.klass          = 'modal';
      this.options        = options;
      this.$dialog        = $(element);
      this.$body          = $('#container');
      this.$backdrop      =
      this.isShown        = null
      this.scrollbarWidth = 0;

      this.setupElement();
    },

    // Popover constructor
    //
    popover: function(element, options) {
      this.klass          = 'popover';
      this.options        = options;
      this.$element       = $(this.options.trigger);
      this.$tip           = $(element);
      this.$body          = $('#container');
      this.$viewport      = this.options.viewport && $(this.options.viewport.selector || this.options.viewport)
      this.$backdrop      =
      this.isShown        = null
      this.scrollbarWidth = 0;

      this.setupElement();
    }

  };

  // Base/common functions to be extended by both types
  //
  Superpop.Base = {

    toggle: function() {
      this[this.isShown ? 'hide' : 'show']();
    },

    backdrop: function(callback) {
      if (this.isShown && this.options.backdrop) {
        this.$backdrop = $('<div class="' + this.klass + '-backdrop"></div>').appendTo($('body'));
        // Backdrop presence implies dismissing the modal/popover on
        // clicking out of its element.
        if (this instanceof Superpop.modal) {
          this.$element.on('click', $.proxy(function(e) {
            if ($(e.target).has(this.$dialog).length) {
              this.hide();
            }
          }, this));
        } else {
          this.$backdrop.on('click', $.proxy(this.hide, this));
        }
        callback();
      } else { callback(); }
    },

    removeBackdrop: function() {
      this.$backdrop && this.$backdrop.remove();
      this.$backdrop = null;
    },

    checkScrollbar: function() {
      if (document.body.clientWidth >= window.innerWidth) return;
      this.scrollbarWidth = this.scrollbarWidth || this.measureScrollbar();
    },

    setScrollbar: function() {
      var bodyPad = parseInt(this.$body.css('padding-right') || 0);
      if (this.scrollbarWidth) this.$body.css('padding-right', bodyPad + this.scrollbarWidth);
    },

    resetScrollbar: function() {
      this.$body.css('padding-right', '');
    },

    measureScrollbar: function() {
      var scrollDiv = document.createElement('div');
      scrollDiv.className = 'modal-scrollbar-measure';
      this.$body.append(scrollDiv);
      var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
      this.$body[0].removeChild(scrollDiv);
      return scrollbarWidth;
    },

    escape: function() {
      if (this.isShown) {
        this.$element.on('keyup', $.proxy(function(e) {
          e.which == 27 && this.hide();
        }, this));
      } else if (!this.isShown) {
        this.$element.off('keyup');
      }
    }

  };

  Superpop.modal.prototype = {

    constructor: Superpop.modal,

    setupElement: function() {
      this.$container = this.options.container ? $(this.options.container) : this.$dialog.parent();
      this.$dialog.addClass('modal');
    },

    show: function() {
      var self = this;

      if (this.isShown) return;
      this.isShown = true;
      this.$dialog.trigger('show', this.$dialog);
      this.wrapDialog();
      this.checkScrollbar();
      this.$body.addClass('modal-open');
      this.setScrollbar();
      this.escape();
      this.$element.on('click', '[data-dismiss="modal"]', $.proxy(this.hide, this));
      this.backdrop(function() {
        self.$container.append(self.$element);
        self.$dialog.show().scrollTop(0);
      });
    },

    hide: function(e) {
      var self = this;

      if (e) e.preventDefault();

      if (!this.isShown) return;
      this.isShown = false;
      this.$dialog.trigger('hide', this.$dialog);
      this.$body.removeClass('no-scroll');
      this.resetScrollbar();
      this.escape();
      this.$dialog.hide();
      this.backdrop(function() {
        self.unwrapDialog();
        self.removeBackdrop();
      });
    },

    wrapDialog: function() {
      if (!this.$dialog.has('a.dismiss-modal').length)
        this.$dialog.prepend($('<a class="dismiss-modal" data-dismiss="modal">×</a>'));

      this.$element = $('<div class="modal-container"><div class="modal-wrap"></div></div>');
      this.$element.find('.modal-wrap').html(this.$dialog);
    },

    unwrapDialog: function() {
      this.$element.detach();
      this.$dialog.find('a.dismiss-modal').remove();
      this.$container.append(this.$dialog);
    }

  };

  $.extend(Superpop.modal.prototype, Superpop.Base);

  Superpop.popover.prototype = {

    constructor: Superpop.popover,

    setupElement: function() {
      this.$tip.addClass('popover');
      if (!this.$tip.has('.arrow').length) {
        this.$tip.prepend(this.$arrow = $('<div class="arrow"></div>'));
      } else {
        this.$arrow = this.$tip.find('.arrow');
      }
      this.$tip.on('click', '[data-dismiss="popover"]', $.proxy(this.hide, this));
    },

    show: function(force) {
      var self = this;

      if (this.isShown && !force) return;
      this.isShown = true;
      // Ensure arrow div and dismiss link are present within the tip
      if (!this.$tip.has('.arrow').length)
        this.$tip.prepend(this.$arrow = $('<div class="arrow"></div>'));
      if (this.options.dismissable && !this.$tip.has('a.dismiss-popover').length)
        this.$tip.prepend($('<a href="#" class="dismiss-popover" data-dismiss="popover">×</a>'));

      this.$tip.trigger('show', this.$tip);
      this.checkScrollbar();
      this.$body.addClass('no-scroll');
      this.setScrollbar();
      this.escape();
      this.backdrop(function() {
        var $tip      = self.$tip,
            placement = self.options.placement,
            autoToken = /\s?auto?\s?/i,
            autoPlace = autoToken.test(placement);

        if (autoPlace) placement = placement.replace(autoToken, '') || 'top';

        self.originalContainer = $tip.parent();

        $tip.find('.arrow').attr('style', '');
        $tip
          .detach()
          .css({ top: 0, left: 0, display: 'block' })
          .addClass(placement);

        self.options.container ? $tip.appendTo(self.options.container) : $tip.insertAfter(self.$element);

        var pos          = self.getPosition(),
            actualWidth  = $tip[0].offsetWidth,
            actualHeight = $tip[0].offsetHeight;

        if (autoPlace) {
          var orgPlacement = placement,
              $parent      = self.options.container ? $(self.options.container) : self.$element.parent(),
              parentDim    = self.getPosition($parent);

          placement = placement === 'bottom' && pos.top + pos.height + actualHeight - parentDim.scroll > parentDim.height ? 'top'    :
            placement === 'top'    && pos.top   * 2 < parentDim.height                                                    ? 'bottom' :
            placement === 'right'  && pos.right + actualWidth      > parentDim.width                                      ? 'left'   :
            placement === 'left'   && pos.left  - actualWidth      < parentDim.left                                       ? 'right'  :
            placement;

          $tip
            .removeClass(orgPlacement)
            .addClass(placement)
        }

        self.placement = placement;

        var calculatedOffset = self.getCalculatedOffset(placement, pos, actualWidth, actualHeight);

        self.applyPlacement(calculatedOffset, placement);

        $(window).on('resize.superpop', $.proxy(self.observeResize, self));
      });

    },

    hide: function(opts) {
      if (!this.isShown) return;
      opts = opts || {};
      var $tip = this.$tip,
          self = this;

      this.isShown = false;
      if (!opts.hasOwnProperty('trigger') || opts.trigger)
        $tip.trigger('hide', $tip);
      this.$body.removeClass('no-scroll');
      this.backdrop(function() {
        $tip.removeClass('in');
        $tip.hide();
        $tip.removeClass(self.placement);
        self.removeBackdrop();
        // Cleanup style attribute and reattach popover to its
        // original position
        $tip.attr('style', '');
        $tip.find('.popover-inner').css({ 'max-height': '', overflow: '' });
        $tip.detach().
             appendTo(self.originalContainer);
        $(window).off('resize.superpop');
      });
    },

    applyPlacement: function(offsetWithDelta, placement) {
      var $tip   = this.$tip,
          width  = $tip[0].offsetWidth,
          height = $tip[0].offsetHeight,
          offset = offsetWithDelta[0];

      // manually read margins because getBoundingClientRect
      // includes difference
      var marginTop  = parseInt($tip.css('margin-top'), 10),
          marginLeft = parseInt($tip.css('margin-left'), 10);

      // we must check for NaN for ie 8/9
      if (isNaN(marginTop))  marginTop  = 0;
      if (isNaN(marginLeft)) marginLeft = 0;

      offset.top  = offset.top  + marginTop;
      offset.left = offset.left + marginLeft;

      // $.fn.offset doesn't round pixel values
      // so we use setOffset directly with our own function B-0
      $.offset.setOffset($tip[0], $.extend({
        using: function(props) {
          $tip.css({
            top: Math.round(props.top),
            left: Math.round(props.left)
          });
        }
      }, offset), 0);

      $tip.addClass('in');

      // Get actual size
      var actualWidth  = $tip[0].offsetWidth,
          actualHeight = $tip[0].offsetHeight;

      // If height changed, fix top offset in top placed popovers
      if (placement == 'top' && actualHeight != height) {
        offset.top = offset.top + height - actualHeight;
      }

      var delta, arrowDelta, arrowPosition, arrowOffsetPosition;

      // For left/right popovers, use original delta calculation and repositioning method
      if (placement.match(/right|left/)) {
        delta = this.getViewportAdjustedDelta(placement, offset, actualWidth, actualHeight);
        if (delta.left) offset.left += delta.left;
        else offset.top += delta.top;
      } else {
        delta = offsetWithDelta[1];
      }

      arrowDelta          = delta.left ? delta.left * 2 - width + actualWidth : delta.top * 2 - height + actualHeight;
      arrowPosition       = delta.left ? 'left'        : 'top';
      arrowOffsetPosition = delta.left ? 'offsetWidth' : 'offsetHeight';

      $tip.offset(offset);
      this.replaceArrow(arrowDelta, $tip[0][arrowOffsetPosition], arrowPosition);

      // In top/bottom placement, set element content max height to
      // prevent overflowing. Recheck height and reset top offset
      // in top placed popovers.
      if (placement.match(/bottom|top/)) {
        var viewportHeight = this.$viewport[0].offsetHeight;
        this.$tip.find('.popover-inner').css({
          'max-height': placement == 'bottom' ? viewportHeight - offset.top - 10 : actualHeight + offset.top - 10,
          overflow: 'auto'
        });
        if (placement == 'top' && actualHeight != $tip[0].offsetHeight) {
          offset.top = offset.top + actualHeight - $tip[0].offsetHeight;
          $tip.offset(offset);
        }
      }
    },

    getPosition: function($element) {
      $element   = $element || this.$element;
      var el     = $element[0],
          isBody = el.tagName == 'BODY'
      return $.extend({}, (typeof el.getBoundingClientRect == 'function') ? el.getBoundingClientRect() : null, {
        scroll: isBody ? document.documentElement.scrollTop || document.body.scrollTop : $element.scrollTop(),
        width:  isBody ? $(window).width()  : $element.outerWidth(),
        height: isBody ? $(window).height() : $element.outerHeight()
      }, isBody ? {top: 0, left: 0} : $element.offset());
    },

    getCalculatedOffset: function(placement, pos, actualWidth, actualHeight) {
      var offset = {}, delta = { left: 0, top: 0 };

      if (placement.match(/bottom|top/)) {
        offset.top  = placement == 'bottom' ? pos.top + pos.height : pos.top - actualHeight;
        offset.left = pos.left + pos.width / 2 - actualWidth / 2;

        // Avoid narrowing by displacing the element to the left
        var viewportWidth   = this.$viewport[0].offsetWidth,
            rightEdgeOffset = offset.left + actualWidth;

        if (rightEdgeOffset > viewportWidth) {
          delta.left  = viewportWidth - rightEdgeOffset;
          offset.left = viewportWidth - actualWidth;
        }
        return [offset, delta];
      } else {
        return placement == 'left'   ? [{ top: pos.top + pos.height / 2 - actualHeight / 2, left: pos.left - actualWidth }, delta] :
            /* placement == 'right' */ [{ top: pos.top + pos.height / 2 - actualHeight / 2, left: pos.left + pos.width   }, delta];
      }
    },

    getViewportAdjustedDelta: function(placement, pos, actualWidth, actualHeight) {
      var delta = { top: 0, left: 0 };
      if (!this.$viewport) return delta;

      var viewportPadding    = this.options.viewport && this.options.viewport.padding || 0,
          viewportDimensions = this.getPosition(this.$viewport);

      if (/right|left/.test(placement)) {
        var topEdgeOffset    = pos.top - viewportPadding - viewportDimensions.scroll,
            bottomEdgeOffset = pos.top + viewportPadding - viewportDimensions.scroll + actualHeight;
        if (topEdgeOffset < viewportDimensions.top) { // top overflow
          delta.top = viewportDimensions.top - topEdgeOffset;
        } else if (bottomEdgeOffset > viewportDimensions.top + viewportDimensions.height) { // bottom overflow
          delta.top = viewportDimensions.top + viewportDimensions.height - bottomEdgeOffset;
        }
      } else {
        var leftEdgeOffset  = pos.left - viewportPadding,
            rightEdgeOffset = pos.left + viewportPadding + actualWidth;
        if (leftEdgeOffset < viewportDimensions.left) { // left
          // overflow
          delta.left = viewportDimensions.left - leftEdgeOffset;
        } else if (rightEdgeOffset > viewportDimensions.width) { // right overflow
          delta.left = viewportDimensions.left + viewportDimensions.width - rightEdgeOffset;
        }
      }

      return delta;
    },

    replaceArrow: function(delta, dimension, position) {
      this.$arrow.css(position, delta ? (50 * (1 - delta / dimension) + '%') : '')
    },

    observeResize: function() {
      if (this.resizing || this.$tip.has('input:focus').length) return;
      this.hide({ trigger: false });
      this.resizing = setInterval($.proxy(function() {
        var container = $(this.options.container)[0],
            size      = { width: container.offsetWidth, height: container.offsetHeight },
            previous  = this.size || { width: 0, height: 0 };

        if (size.width === previous.width && size.height === previous.height) {
          clearTimeout(this.resizing);
          this.resizing = this.size = null;
          this.show();
        } else {
          this.size = size;
        }
      }, this), 250);
    }

  };

  $.extend(Superpop.popover.prototype, Superpop.Base);

  Superpop.DEFAULTS = {
    backdrop: true,
    show: true,
    viewport: {
      selector: 'body',
      padding: 0
    },
    container: 'body',
    placement: 'auto'
  };

  // Plugin function definition

  var old = $.fn.superpop;

  $.fn.superpop = function(option, action) {
    return this.each(function() {
      var $this    = $(this),
          superpop = $this.data('sp-instance'),
          options  = $.extend({}, Superpop.DEFAULTS, $this.data(), typeof option === 'object' && option);

      if (!superpop) $this.data('sp-instance', (superpop = Superpop.build(this, options)));
      if (typeof option === 'string') superpop[option](action);
      else if (options.show)          superpop.show();
    });
  };

  // No conflict
  //
  $.fn.superpop.noConflict = function() {
    $.fn.superpop = old;
    return this;
  };

  // HTML data attributes API
  //
  $('body').on('click.superpop', '[data-superpop]', function(e) {
    var $this   = $(this),
        href    = $this.prop('href'),
        $target = $($this.attr('data-target') || (href && href.replace(/.*(?=#[^\s]+$)/, ''))),
        spop    = $target.data('sp-instance'),
        opt     = spop ?
          (spop.klass === 'modal' ? spop.options.trigger = this : spop.$element = $(this)) && 'toggle' :
          $.extend({ trigger: this }, $target.data(), $this.data());

    $target.superpop(opt);
    return false;
  });

}));
