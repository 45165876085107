;(function(window, document, navigator, $, google) {

  "use strict";

  var Soysuper = window.Soysuper = window.Soysuper || {};

  function SupersFinder(opts) {
    if (!(this instanceof SupersFinder)) return new SupersFinder(opts);
    opts           = opts || {};
    this.endpoint  = opts.endpoint || window.location.pathname;
    this.type      = opts.type || 'nearest';
    this.insertion = opts.insertion;
    this.gmaps     = google && google.maps;
    if (this.gmaps === void 0) throw new Error(
      "Google maps is not available"
    );
    this.initialize();
  }

  SupersFinder.prototype = {
    constructor: SupersFinder,

    initialize: function() {
      if (this.type === 'nearest') {
        this.withCurrentPosition(function(position) {
          this.fetch({ position: position });
        });
      } else {
        this.fetch();
      }
    },

    withCurrentPosition: function(callback) {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          $.proxy(callback, this),
          null,
          { enableHighAccuracy: true }
        );
      }
    },

    fetch: function(opts) {
      opts = opts || {};
      var position = opts.position,
            finder = this;

      if (this.type === 'nearest' && position) {
        var latitude  = position.coords.latitude,
            longitude = position.coords.longitude;

        return $.ajax(this.endpoint, {
          dataType: this.dataType = 'html',
          data: {
            latitude: latitude,
            longitude: longitude
          },
          success: function(data, textStatus, jqXHR) {
            finder.process(data, textStatus, jqXHR);
          }
        });
      } else {
        return $.ajax(this.endpoint, {
          cache: false,
          dataType: this.dataType = 'json',
          success: function(data, textStatus, jqXHR) {
            finder.process(data, textStatus, jqXHR);
          }
        });
      }
    },

    process: function(data, textStatus, jqXHR) {
      if (this.dataType === 'html' && this.insertion) {
        for (var method in this.insertion) {
          var $root = this.insertion[method],
              $data = $(data);
          $data[method]($root);
          this.initializeMap(
            JSON.parse($root.find('script[type="application/json"]').text())
          );
        }
      } else {
        if (!$.isEmptyObject(data.markers)) {
          this.initializeMap(data);
        } else {
          $('p#map-header, #map').hide();
        }
      }
    },

    initializeMap: function(opts) {
      opts = opts || {};
      var options = opts.options || {},
          markers = opts.markers,
             elId = opts.el || 'map',
               el = document.getElementById(elId);

      if ($.isEmptyObject(markers) || !el) return;

      this.map = new this.gmaps.Map(
        el, $.extend(this.defaultMapOptions(), options)
      );
      this.setMarkers(markers);
      // Reset zoom after fitting bounds if provided
      if (options.zoom) {
        var finder   = this,
            listener = this.gmaps.event.addListener(this.map, 'bounds_changed', function(ev) {
              this.setZoom(options.zoom);
              finder.gmaps.event.removeListener(listener);
            });
      }
    },

    setMarkers: function(markers) {
      var finder = this,
          bounds = new this.gmaps.LatLngBounds();
      $.each(markers, function(_, data) {
        var position = new finder.gmaps.LatLng(data.position.latitude, data.position.longitude),
            marker   = new finder.gmaps.Marker({
              map: finder.map,
              position: position,
              title: data.title
            }),
            info    = new finder.gmaps.InfoWindow({
              content: data.info
            });
        bounds.extend(position);
        finder.gmaps.event.addListener(marker, 'click', function() {
          info.open(finder.map, marker);
        });
      });
      this.map.fitBounds(bounds);
    },

    defaultMapOptions: function() {
      return {
        mapTypeId: this.gmaps.MapTypeId.ROADMAP,
        disableDefaultUI: true
      };
    }
  };

  Soysuper.SupersFinder = SupersFinder;

})(window, document, navigator, jQuery, window.google);
