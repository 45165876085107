//
// Soysuper js for the "static" version
//
$(function() {

  var oldAndroid = function() {
    var ua = window.navigator.userAgent;
    return ua.match(/Android 4.[12]/) && ua.match(/AppleWebKit\/534.30/);
  };

  // PJAX powered infinite scroll pager loading
  var activatePager = function(opts) {
    opts = opts || {};
    var $pager   = $('#pager'),
        $footer  = $('#footer'),
        $spinner = $('.loading'),
        $actions = $('#subheader, .display.seo, .actions.order, .boxedlist-wrapper');

    if ($pager.length) {
      $footer.hide();
      var qsparam = window.location.search.match(/page=\d+/),
             page = qsparam ? parseInt(qsparam[0].replace(/page=(\d+)/, "$1"), 10) : 1;

      if (page > 1) {
        $actions.hide();
        $(document).scrollTop(50);
      }

      $pager.pager({
        container: opts.container || '.productlist',
        page: page,
        beforeSend: function() {
          $spinner.show();
        },
        complete: function() {
          $spinner.hide();
          if (!this.hasNext) $footer.show();
          if (this.anchors[1]) $actions.show();
        },
        error: function(xhr) {
          if (xhr.status === 404) {
            $footer.show();
          }
        }
      });
    }
  };

  $(document).on('pager:activate', function(e, opts) {
    activatePager(opts);
  });

  // Search box and new promise box autocomplete
  var typeaheadSettings = function(opts) {
    opts = opts || {};
    return {
      minLength: 2,
      container: opts.container || $('section.autocomplete'),
      item: '<li><a href="#" class="suggestion"></a></li>',
      source: function(query, process) {
        $.ajax('/search/suggest', {
          data: { q: query },
          success: function(data) {
            process(data);
          }
        });
        return;
      }
    };
  };

  $('#searchbox .typeahead').typeahead(typeaheadSettings({
    container: $('#searchbox section.autocomplete')
  }));
  $('#searchbox-on-404 .typeahead').typeahead(typeaheadSettings({
    container: $('#searchbox-on-404 section.autocomplete')
  }));
  $('#promisebox .typeahead').typeahead(typeaheadSettings({
    container: $('#promisebox section.autocomplete')
  }));

  $('#searchbox').on('suggestion:selected', function(e) {
    $('#searchbox form').submit();
    return false;
  });
  $('#searchbox-on-404').on('suggestion:selected', function(e) {
    $('#searchbox-on-404 form').submit();
    return false;
  });

  // Search box reset
  $(document).on('click', '#searchbox i.i-fss-close-01', function(e) {
    $(this).siblings('input:text').val('');
  });

  // Messages close button
  $(document).on('click', 'section.message a.close', function(e) {
    $(this).parent('section.message').fadeOut('fast');
  });

  // Home banner related functions
  function hideBanner(meth) {
    meth = meth || 'slideUp';
    $('.tour-intro')[meth]('fast', function() {
      var $this = $(this);
      $this.removeClass('opened');
      document.cookie = 'banner=0; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/';
      $('#tour-intro-open').show().one('click', function(ev) {
        ev.preventDefault();
        $.proxy(showBanner, this)($this);
      });
    });
  }

  function showBanner($banner) {
    var $openbtn = $(this);
    $banner.slideDown('fast', function() {
      $banner.addClass('opened');
      $openbtn.hide();
      document.cookie = 'banner=1; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/';
    });
  }

  $(document).on('click', '.tour-intro a.close', function(e) {
    e.preventDefault();
    hideBanner();
  });
  if (~document.cookie.indexOf('banner=0')) {
    hideBanner('hide');
  }

  var getContext = function() {
    var $script = $('script[id=visibility]');
    if ($script.length) return JSON.parse($script[0].innerHTML);
  };

  var pushProduct = function(ev) {
    var $link     = $(ev.currentTarget),
        vizzagent = typeof Visibility !== 'undefined' ? new Visibility.Agent() : null,
        context   = getContext();

    if ($link.hasClass('disabled')) return false;
    $link.addClass('disabled');
    $.ajax($link.attr('href'), {
      type: 'POST',
      data: $link.data(),
      success: function(resp) {
        if (vizzagent) {
          vizzagent.save({ event: 'cart', product: $link.data('product_id') }, {
            complete: function() {
              window.location.reload();
            }
          });
        } else {
          window.location.reload();
        }
      }
    });
    return false;
  };

  var pushPromise = function(ev) {
    ev.preventDefault();
    var terms   = $(ev.target).find('input[type=text]').val(),
        context = getContext();

    if (!terms) return;
    $.ajax('/cart/items', {
      type: 'POST',
      data: { terms: terms },
      success: function(resp) {
        window.location.reload();
      }
    });
  };

  var pushZipcode = function(ev) {
    var $target  = $(ev.target),
        $zipcode = $(ev.target).find('input[name=zipcode]');

    $target.siblings('em.formtip.error.inlightbox').remove();
    $zipcode.parent('p').removeClass('error');

    $.ajax('/localize', {
      type: 'POST',
      data: { zipcode: $zipcode.val() },
      success: function(resp) {
        window.location.reload();
      },
      error: function(xhr) {
        var resp = JSON.parse(xhr.responseText);
        $zipcode.parent('p').addClass('error');
        $zipcode.parents('.popover').css('height', 'auto');
        $target.after($('<em class="formtip error inlightbox"></em>').
                text(resp.fields && resp.fields.zipcode || ''));
      }
    });

    return false;
  };

  if ($('.productlist, .product, .oneproduct, .comments-list').length) {
    $('.productlist, .product, .oneproduct, .comments-list').on('click', '.newproduct', pushProduct);
  }
  if ($('.addproduct form').length) {
    $('.addproduct form input[type=text]').on('keyup', function() {
      var $this = $(this), $button = $this.siblings().find('button[type=submit]');
      if ($this.val()) { $button.removeClass('disabled'); }
      else             { $button.addClass('disabled'); }
    });
    $('.addproduct form').submit(pushPromise);
  }

  $('#zipcode-popover form').on('submit', pushZipcode);

  // Mode detection object
  function ModeHandler() {
    if (!(this instanceof ModeHandler)) return new ModeHandler();
    this.mq = Modernizr.mq;
    var self = this;
    $(window).on('resize', function() {
      if (self.modeChanged()) {
        $.each(self._callbacks, function(_, cb) {
          cb(self.mode);
        });
      }
    });
    this.onModeChange(function() { self.checkUrl(); });
    this.checkUrl();
  }

  ModeHandler.prototype = {
    constructor: ModeHandler,

    // Test old ie by means of Modernizr' mq polyfill, since it
    // will always return false in old ie, given a valid query.
    oldIE: function() {
      return this.mq('screen');
    },

    // Test media queries (or width in old IE).
    _getCurrentMode: function() {
      if (this.oldIE()) {
        var doc         = window.document,
            docElem     = doc.documentElement,
            docElemProp = docElem.clientWidth,
            currWidth   = doc.compatMode === 'CSS1Compat' && docElemProp || doc.body.clientWidth || docElemProp;
        if (currWidth > 991) {
          return 'desktop';
        } else if (currWidth < 768) {
          return 'mobile';
        } else {
          return 'tablet';
        }
      } else {
        if (this.mq('(min-width: 992px)')) {
          return 'desktop';
        } else if (this.mq('(max-width: 767px)')) {
          return 'mobile';
        } else {
          return 'tablet';
        }
      }
    },

    // Subscribe handlers to changes in mode
    onModeChange: function(callback) {
      this._callbacks = this._callbacks || [];
      this._callbacks.push(callback);
    },

    // returns 'desktop', 'tablet' or 'mobile', depending on
    // current applicable media query, retaining
    // matchMedia.matches test.
    getCurrentMode: function() {
      return this.mode || (this.mode = this._getCurrentMode());
    },

    modeChanged: function() {
      var mode = this._getCurrentMode();
      if (mode !== this.mode) {
        this.mode = mode;
        return true;
      }
    },

    checkUrl: function() {
      var route   = window.location.pathname,
          curmode = this.getCurrentMode();

      if (this.categoriesRe.test(route) && !this.getHash() && curmode === 'desktop') {
        window.location.assign(route + '#products');
      }
    },

    categoriesRe: /^\/c(?:\/.*)?$/,

    getHash: function() {
      var match = window.location.href.match(/#(.*)$/);
      return match ? match[1] : '';
    }
  };

  var modeHandler = new ModeHandler();

  // Remove carousel in mobile mode on clicking #start
  var setIntro = function(mode) {
    $('#header').css({ height: '0px', overflow: 'hidden' });
    $('#start').on('click', function(ev) {
      ev.preventDefault();
      $('.tour-intro').slideUp('fast', function() {
        $('#header').attr('style', '').css('height', 'auto');
      });
    });
  };

  if ($('.tour-intro.opened').length &&
      modeHandler.getCurrentMode() !== 'desktop' &&
      !~document.cookie.indexOf('banner=0')) {
    setIntro();
  }

  // "Popoverize" leftcolumn when not in desktop
  var leftcolumnDisplay = function(mode) {
    if (mode !== 'desktop') {
      $('#leftcolumn').addClass('popover');
    } else {
      $('#leftcolumn').removeClass('popover');
    }
  };

  if ($('#leftcolumn').length) {
    modeHandler.onModeChange(leftcolumnDisplay);
    leftcolumnDisplay(modeHandler.getCurrentMode());
  }

  var handleFiltersDisplay = function(mode) {
    var actions = {
      expand: function() {
        $(this).find('h3').removeClass('closed').
                next('.selectoralike').show();
      },
      collapse: function() {
        $(this).find('h3').addClass('closed').
                next('.selectoralike').hide();
      }
    };
    var $filters = $('#leftcolumn .filters .filter');
    $filters.each(function() {
      var action = $(this).data(mode);
      if (action) actions[action].call(this);
    });
    var $catfilter = $('#leftcolumn .filters #categories-filter');
    if (window.location.pathname.match(/^\/c/) &&
        window.location.hash === '#products' &&
        mode !== 'desktop') {
      $catfilter.show();
    } else {
      $catfilter.hide();
    }
  };

  // Collapse/expand filters lightbox/column depending on mode
  if ($('#leftcolumn .filters').length) {
    handleFiltersDisplay(modeHandler.getCurrentMode());
    modeHandler.onModeChange(function(mode) {
      handleFiltersDisplay(mode);
    });
  }

  // Feedback modal
  var $feedback = $('#feedback');
  if ($feedback.length) {
    $(document).on('submit', '#feedback form', function() {
      $feedback.find('em.formtip.error').remove();
      $feedback.find('p').removeClass('error');

      var $button = $feedback.find('button'), buttonText = $button.text();
      $button.
        addClass('disabled').
        prop('disabled', 'disabled').
        text($button.data('alt')).
        data('alt', buttonText);

      $.ajax('/feedback', {
        type: 'POST',
        data: $feedback.find('form').serialize(),
        success: function() {
          $feedback.find('h4, .feedback-desc, fieldset, .actions').hide();
          $feedback.find('.success').fadeIn('fast');
          $feedback.find('input, textarea').val('');
          $('#feedback').one('hide', function() {
            $feedback.find('h4, .feedback-desc, fieldset, .actions').show();
            $feedback.find('.success').hide();
          });
        },
        error: function(response) {
          var resp = JSON.parse(response.responseText);

          $.each(resp.fields, function(attr, msg) {
            var $em = $('<em class="formtip error">');
            $feedback.find('[name=' + attr + ']').
              parent('p').
              addClass('error').
              prepend($em);
            $em.text(msg);
          });
        },
        complete: function() {
          $button.
            removeClass('disabled').
            removeAttr('disabled').
            data('alt', $button.text()).
            text(buttonText);
        }
      });
      return false;
    });
  }

  // Toggle products/categories view
  var toggleProducts = function() {
    var $categories = $('.basiclist.categories'),
        $products   = $('.basiclist.productlist, .order, .display'),
        $catfilter  = $('#leftcolumn .filters #categories-filter'),
        catslength  = $categories.find('li').length;

    if (window.location.hash === '#products') {
      if ($categories.is(':visible')) {
        $categories.fadeOut('fast', function() {
          $products.fadeIn('fast');
        });
      } else {
        $products.fadeIn('fast');
      }
      $catfilter.show();
      $(document).trigger('pager:activate');
    } else {
      if (modeHandler.getCurrentMode() !== 'desktop' && catslength > 1) {
        $products.hide();
        $catfilter.hide();
        $categories.show();
      } else {
        $categories.hide();
        $products.show();
        $catfilter.show();
      }
    }
  };
  $(window).on('hashchange', toggleProducts);
  toggleProducts();

  // Inconditionally activate pager in search and brand pages
  if (window.location.pathname.match(/^\/search|marca/)) $(document).trigger('pager:activate');
  // Activate it in user's (comments) page
  if (window.location.pathname.match(/^\/u\//)) {
    $(document).trigger('pager:activate', {
      container: '.comments-list'
    });
  }

  modeHandler.onModeChange(function(mode) {
    var $intro = $('.tour-intro');
    // Ensure searchbox is shown in desktop/tablet mode by "resetting" styles
    if (mode !== 'mobile') {
      $('#searchbox, #header').attr('style', '');
      if ($intro.length && $intro.hasClass('opened')) $intro.show();
    } else {
      if (!$('#searchbox').is(':visible')) $('#page').removeClass('withsearchbox');
      if ($intro.length) {
        if ($intro.hasClass('opened')) {
          setIntro();
        } else {
          $('#header').attr('style', '').css('height', 'auto');
        }
      }
    }
  });

  // Product page gallery initialization
  if ($('.js-product-image .img:not(.noimg)').length) {
    var mode = modeHandler.getCurrentMode();
    $('.product').gallery({
      mode: mode === 'tablet' ? 'mobile' : mode
    });
  }

  // Tour
  if ($('[href="/?tour=1"]').length && window.location.pathname === '/') {
    var tour = new Tour({
      mode: modeHandler.getCurrentMode(),
      start: window.location.search.match(/tour=1/)
    });
    $('[href="/?tour=1"]').on('click', function(e) {
      e.preventDefault();
      if (modeHandler.getCurrentMode() === 'desktop') {
        if ($('.tour-intro').is(':visible')) {
          hideBanner('hide');
          tour.onexit(function() {
            $('#tour-intro-open').click();
          });
          tour.oncomplete(function() {
            $('#tour-intro-open').click();
          });
        }
      } else {
        $('#start').click();
      }

      setTimeout(function() {
        var $scrollable  = $('#container, #leftcolumn, #rightcolumn'),
            removeScroll = function() { $scrollable.removeClass('noscroll'); };
        $scrollable.addClass('noscroll');
        tour.onexit(removeScroll);
        tour.oncomplete(removeScroll);
        tour.start();
      }, 300);

      modeHandler.onModeChange(function(mode) {
        tour.exit();
        tour.loadSteps(mode);
      });
    });
  }

  // hide store link when we can detect are not relevant for the user
  if ( window.navigator.userAgent.match(/Android/) ) $('.i-ss-app-store').hide();
  if ( window.navigator.userAgent.match(/iPhone/) ) $('.i-ss-google-play').hide();

});
